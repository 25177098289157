import React from 'react'

import { InputBase, InputBaseProps } from '@mantine/core'
import { IMaskInput } from 'react-imask'

import { MASKS } from 'src/helpers/mask'

interface MaskedInputProps extends InputBaseProps {
  mask: keyof typeof MASKS
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
}

const MaskedInput: React.FC<MaskedInputProps> = ({
  mask,
  onChange,
  ...props
}) => {
  return (
    <InputBase
      component={IMaskInput}
      mask={MASKS[mask]}
      onAccept={(value) => onChange(value)}
      {...props}
    />
  )
}

export default MaskedInput

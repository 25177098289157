import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from 'react'

import { Progress, StudentLevel } from 'types/graphql'
import { LevelUnit } from 'types/levelUnit'

type Props = {
  children: ReactNode
}

type Levels = {
  currentLevel: StudentLevel
  levels: StudentLevel[]
}

type LevelsState = {
  levels: StudentLevel[]
  currentLevel: StudentLevel
  levelUnits: LevelUnit[]
  levelProgress: {
    extra: {
      progress: Progress[]
    }
    required: {
      progress: Progress[]
    }
  }
  setLevels: (levels: Levels) => void
  setLevelsUnits: (levelUnits: LevelUnit[]) => void
  setLevelProgress: (progress: {
    required: { progress: Progress[] }
    extra: { progress: Progress[] }
  }) => void
}

const LevelContext = createContext<LevelsState | undefined>(undefined)

const useLevel = (): LevelsState => {
  const context = useContext(LevelContext)

  if (!context) {
    throw new Error('useLevel must be used within LevelProvider')
  }

  return {
    levels: context.levels,
    currentLevel: context.currentLevel,
    levelUnits: context.levelUnits,
    levelProgress: context.levelProgress,
    setLevels: context.setLevels,
    setLevelsUnits: context.setLevelsUnits,
    setLevelProgress: context.setLevelProgress,
  }
}

const LevelProvider = ({ children }: Props) => {
  const [levelUnits, setLevelUnitsObject] = useState<LevelUnit[]>([])
  const [levelProgress, setLevelProgressObject] = useState<{
    extra: { progress: Progress[] }
    required: { progress: Progress[] }
  }>({
    extra: { progress: [] },
    required: { progress: [] },
  })

  const [levelsObject, setLevelsObject] = useState<Levels>({
    currentLevel: {} as StudentLevel,
    levels: [] as StudentLevel[],
  })

  const setLevelsAndCurrentLevel = useCallback((levels: Levels) => {
    setLevelsObject(levels)
  }, [])

  const setLevelsUnits = useCallback((levelUnits: LevelUnit[]) => {
    setLevelUnitsObject(levelUnits)
  }, [])

  const setLevelProgress = useCallback(
    (progress: {
      required: { progress: Progress[] }
      extra: { progress: Progress[] }
    }) =>
      setLevelProgressObject({
        extra: { progress: progress.extra.progress },
        required: { progress: progress.required.progress },
      }),
    []
  )

  const value = {
    currentLevel: levelsObject.currentLevel,
    levels: levelsObject.levels,
    levelUnits,
    levelProgress,
    setLevels: setLevelsAndCurrentLevel,
    setLevelsUnits,
    setLevelProgress,
  }

  return <LevelContext.Provider value={value}>{children}</LevelContext.Provider>
}

export { LevelProvider, useLevel, LevelContext }

/**
 * Sanatize phone number
 * @param phone string
 * @returns Sanatized phone number
 */
export const sanatizePhone = (phone: string) =>
  phone.replace('+55', '').replace(/\D/g, '')

/**
 * Sanatize zipcode
 * @param zipcode string
 * @returns Sanatized zipcode
 */
export const sanatizeZipcode = (zipcode: string) => zipcode.replace(/\D/g, '')

import React from 'react'

import {
  ActionIcon,
  Divider,
  Drawer,
  Group,
  Indicator,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  Icon,
  IconBell,
  IconClock,
  IconMessage,
  IconUserPlus,
} from '@tabler/icons-react'
import {
  LeadNotificationTypeEnum,
  ListNotificationsQuery,
  ListNotificationsQueryVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import {
  LIST_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATION_AS_READ_MUTATION,
} from 'src/graphql/acquisition/lead-notifications'
import { formatDate, formatDateRelative } from 'src/lib/date'
import { useViewLead } from 'src/providers/acquisition/lead/ViewLeadProvider'

const LEAD_NOTIFICATION_DISPLAY_INFO: Record<
  LeadNotificationTypeEnum,
  {
    icon: Icon
    title: string
  }
> = {
  NEW_LEAD: {
    icon: IconUserPlus,
    title: 'Nova oportunidade',
  },
  NEW_MESSAGE: {
    icon: IconMessage,
    title: 'Nova mensagem',
  },
  WAITING_TOO_LONG: {
    icon: IconClock,
    title: 'Oportunidade em atraso',
  },
}

const Notification = ({
  notification,
  onNotificationClick,
}: {
  notification: ListNotificationsQuery['ListNotifications'][number]
  onNotificationClick: () => void
}) => {
  const [markNotificationAsRead] = useMutation(
    MARK_NOTIFICATION_AS_READ_MUTATION
  )
  const { openLead } = useViewLead()

  const { icon: Icon, title } =
    LEAD_NOTIFICATION_DISPLAY_INFO[notification.type]

  const handleClick = () => {
    markNotificationAsRead({
      variables: {
        input: {
          notificationId: notification.id,
        },
      },
      refetchQueries: [LIST_NOTIFICATIONS_QUERY],
    })

    if (notification.leadId) openLead(notification.leadId)
    if (onNotificationClick) onNotificationClick()
  }

  return (
    <Indicator
      disabled={notification.read}
      color={notification.read ? 'gray.5' : 'red.9'}
      offset={15}
      position="top-end"
    >
      <Group
        p="sm"
        justify="space-between"
        align="baseline"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
        bg={notification.read ? 'gray.0' : 'gray.1'}
      >
        <Stack gap={3}>
          <Group gap="xs">
            <ThemeIcon
              size={20}
              radius="xl"
              color="gray.8"
              variant="transparent"
            >
              <Icon size={14} />
            </ThemeIcon>
            <Title order={5}>{title}</Title>
          </Group>

          <Text fz={13}>{notification.content}</Text>

          <Tooltip.Floating label={formatDate(notification.createdAt)} fz="xs">
            <Text fz={12} c="dimmed">
              {formatDateRelative(notification.createdAt)}
            </Text>
          </Tooltip.Floating>
        </Stack>
      </Group>
    </Indicator>
  )
}

export function NotificationsSidebar() {
  const { data, loading } = useQuery<
    ListNotificationsQuery,
    ListNotificationsQueryVariables
  >(LIST_NOTIFICATIONS_QUERY, { pollInterval: 10_000 })

  const [
    notificationsDrawerOpened,
    { open: openNotificationsDrawer, close: closeNotificationsDrawer },
  ] = useDisclosure(false)

  const unreadNotificationsCount =
    data?.ListNotifications?.filter((notification) => !notification.read)
      .length ?? 0

  return (
    <>
      <Tooltip
        label="Notificações"
        position="right"
        transitionProps={{ duration: 0 }}
      >
        <Indicator
          color="red"
          size={9}
          disabled={unreadNotificationsCount === 0}
        >
          <ActionIcon
            variant="subtle"
            color="dark.3"
            onClick={openNotificationsDrawer}
          >
            <IconBell size={18} />
          </ActionIcon>
        </Indicator>
      </Tooltip>

      <Drawer
        opened={notificationsDrawerOpened}
        onClose={closeNotificationsDrawer}
        position="left"
        withCloseButton={false}
      >
        <Drawer.Header p="xs">
          <Group justify="space-between" w="100%">
            <Group>
              <IconBell size={20} />
              <Title order={4}>Notificações</Title>
            </Group>

            <Drawer.CloseButton />
          </Group>
        </Drawer.Header>

        <Drawer.Body p={0}>
          <Stack mt="md" gap={0}>
            {loading && !data ? (
              <Text>Carregando...</Text>
            ) : data?.ListNotifications?.length === 0 ? (
              <Text>Nenhuma notificação</Text>
            ) : (
              data?.ListNotifications?.map((notification, index) => (
                <React.Fragment key={index}>
                  <Stack gap="xs" key={notification.id}>
                    <Notification
                      notification={notification}
                      onNotificationClick={closeNotificationsDrawer}
                    />
                  </Stack>
                  {index < data.ListNotifications.length - 1 && <Divider />}
                </React.Fragment>
              ))
            )}
          </Stack>
        </Drawer.Body>
      </Drawer>
    </>
  )
}

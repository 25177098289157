// This page will be rendered when an error makes it all the way to the top of the
// application without being handled by a Javascript catch statement or React error
// boundary.
//
// You can modify this page as you wish, but it is important to keep things simple to
// avoid the possibility that it will cause its own error. If it does, Redwood will
// still render a generic error page, but your users will prefer something a bit more
// thoughtful :)

// This import will be automatically removed when building for production
import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

export const ERROR_STYLES = `
  html, body {
    margin: 0;
  }
  html * {
    box-sizing: border-box;
  }
  main {
    display: flex;
    align-items: center;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    text-align: center;
    background-color: #FBFBFB;
    height: 100vh;
    padding: 0 0.5rem;
  }
  section {
    background-color: white;
    border-radius: 12px;
    width: 32rem;
    padding: 2rem;
    margin: 0 auto;
    border: 1px solid #DEE2E6;
  }
  h1 {
    font-size: 2rem;
    margin: 0;
    font-weight: 500;
    line-height: 1;
    color: #2D3748;
  }
  button {
    box-sizing: border-box;
    text-transform: none;
    padding: 0 1.5rem;
    appearance: none;
    text-decoration: none;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid transparent;
    height: 3rem;
    vertical-align: middle;
    display: block;
    border-radius: 1.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0.5rem;
    background: #ED1941;
    color: #fff;
    min-width: 8rem;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
`

export default DevFatalErrorPage ||
  (() => (
    <main>
      <style
        dangerouslySetInnerHTML={{
          __html: ERROR_STYLES,
        }}
      />
      <section data-sel="fatal-error">
        <h1>Something went wrong</h1>
        <p>
          Click below to reload the page and try again or{' '}
          <a href="/learning/dashboard">here</a> to go back to homepage:
        </p>
        <div className="actions">
          <button data-sel="refresh" onClick={() => window.location.reload()}>
            Reload
          </button>
        </div>
      </section>
    </main>
  ))

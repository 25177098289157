export const LIST_NOTIFICATIONS_QUERY = gql`
  query ListNotificationsQuery {
    ListNotifications {
      id
      type
      content
      read
      leadId
      createdAt
    }
  }
`

export const MARK_NOTIFICATION_AS_READ_MUTATION = gql`
  mutation MarkNotificationAsReadMutation(
    $input: MarkNotificationAsReadInput!
  ) {
    MarkNotificationAsRead(input: $input)
  }
`

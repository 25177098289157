// utils/formatWithMask.ts

import { IMask } from 'react-imask'

export const MASKS = {
  phone: ['(00) 0000-0000', '(00) {9}0000-0000'],
  cep: '00000-000',
}

/**
 * Format value with mask pattern
 * @param value: string - value to format
 * @param mask: string - mask name
 * @return string
 * @example
 *   const phone = mask('11987654321', 'phone');
 *   console.log(phone); // log: (11) 98765-4321
 *
 *   const cep = mask('12345678', 'cep');
 *   console.log(cep); // log: 12345678
 **/

export const mask = (value: string, mask: keyof typeof MASKS): string => {
  const createMask = IMask.createMask<any>({ mask: MASKS[mask] })
  createMask.resolve(value)

  return createMask.value
}

import React, { useEffect, useRef, useState } from 'react'

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Group,
  Image,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  Tooltip,
  Transition,
} from '@mantine/core'
import {
  IconAlertCircle,
  IconClock,
  IconLoader,
  IconTargetArrow,
} from '@tabler/icons-react'
import {
  GetCustomerChatQuery,
  GetCustomerChatQueryVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import {
  GET_CUSTOMER_CHAT_QUERY,
  RESEND_MESSAGE_MUTATION,
  SEND_MESSAGE_MUTATION,
} from 'src/graphql/acquisition/lead-management'
import { dayjs, formatDate, formatDateRelative } from 'src/lib/date'

type Message = GetCustomerChatQuery['GetCustomerChat']['messages'][number]

type ChatProps = {
  initialMessages?: Message[]
  customerId: number
}

const MarketingMessage = ({ message }: { message: Message }) => {
  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Group justify="flex-end" gap="xs" wrap="nowrap">
          <Stack gap={2} align="flex-end">
            <Box
              px={10}
              py={8}
              maw="60%"
              style={(theme) => ({
                backgroundColor: theme.colors.violet[6],
                color: 'white',
                wordWrap: 'break-word',
                alignSelf: 'flex-end',
                borderRadius: 8,
              })}
            >
              <Group gap="xs">
                <Text size="sm" ta="right" fs="italic">
                  {message.content}
                </Text>
              </Group>
            </Box>
            <Tooltip label={formatDate(message.createdAt)} fz="xs">
              <Text fz={10} c="dimmed">
                {dayjs(message.createdAt).isSame(dayjs(), 'day')
                  ? formatDateRelative(message.createdAt)
                  : formatDate(message.createdAt)}
              </Text>
            </Tooltip>
          </Stack>

          <Tooltip label="Mensagem de marketing enviada automaticamente">
            <Avatar
              color="initials"
              size="sm"
              radius="xl"
              variant="outline"
              name="CNA"
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <IconTargetArrow size={16} />
            </Avatar>
          </Tooltip>
        </Group>
      </Stack>
    </Group>
  )
}

const ConsultantMessage = ({ message }: { message: Message }) => {
  const [resendMessage, { loading: resendMessageLoading }] = useMutation(
    RESEND_MESSAGE_MUTATION
  )
  const didMessageFail = message.deliveryStatus === 'ERROR'

  return (
    <Group key={message.id} align="flex-end" justify="flex-end" gap="sm">
      <Stack gap={0} align="flex-end" flex={1}>
        <Text fw={500} size="xs" c="dark.3">
          {message.sender.name}
        </Text>
        <Box
          px={10}
          py={8}
          my={4}
          maw="60%"
          bd={didMessageFail ? '1px dashed var(--mantine-color-red-6)' : 'none'}
          style={(theme) => ({
            backgroundColor: didMessageFail
              ? theme.colors.white
              : theme.colors.blue[6],
            color: didMessageFail ? theme.colors.red[6] : 'white',
            wordWrap: 'break-word',
            alignSelf: 'flex-end',
            borderRadius: 8,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 8,
          })}
        >
          <Text size="sm">{message.content}</Text>
        </Box>

        {message.deliveryStatus === 'PENDING' && (
          <Tooltip label="Enviando mensagem" fz="xs">
            <IconClock size={12} color="var(--mantine-color-gray-6)" />
          </Tooltip>
        )}

        {message.deliveryStatus === 'ERROR' && (
          <>
            {resendMessageLoading && (
              <Tooltip label="Tentando enviar novamente" fz="xs">
                <Group gap={5}>
                  <IconLoader size={16} color="var(--mantine-color-red-6)" />
                </Group>
              </Tooltip>
            )}

            {!resendMessageLoading && (
              <Tooltip label="Clique para enviar novamente" fz="xs">
                <Group gap={5}>
                  <Anchor
                    size="xs"
                    c="red.6"
                    td="underline"
                    onClick={() =>
                      resendMessage({ variables: { messageId: message.id } })
                    }
                  >
                    Erro ao enviar. Tentar novamente?
                  </Anchor>

                  <IconAlertCircle
                    size={16}
                    color="var(--mantine-color-red-6)"
                  />
                </Group>
              </Tooltip>
            )}
          </>
        )}

        <Tooltip label={formatDate(message.createdAt)} fz="xs">
          <Text fz={10} c="dimmed">
            {dayjs(message.createdAt).isSame(dayjs(), 'day')
              ? formatDateRelative(message.createdAt)
              : formatDate(message.createdAt)}
          </Text>
        </Tooltip>
      </Stack>

      <Avatar
        color="initials"
        size="sm"
        radius="xl"
        variant="outline"
        name={message.sender.name}
      />
    </Group>
  )
}

const CustomerMessage = ({ message }: { message: Message }) => {
  return (
    <Group key={message.id} align="flex-end" justify="left" gap="sm">
      <Stack gap={0} align="flex-start" flex={1}>
        <Text fw={500} size="xs" c="dark.3">
          {message.sender.name}
        </Text>
        <Box
          px={10}
          py={8}
          my={4}
          maw="60%"
          style={(theme) => ({
            backgroundColor: theme.colors.gray[3],
            color: theme.black,
            wordWrap: 'break-word',
            alignSelf: 'flex-start',
            borderRadius: 8,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 0,
          })}
        >
          {message.contentType === 'AUDIO' && (
            <audio src={message.mediaUrl} controls />
          )}

          {message.contentType === 'IMAGE' && (
            <Tooltip label="Clique para ver a imagem">
              <Anchor href={message.mediaUrl} target="_blank">
                <Image src={message.mediaUrl} alt="Mensagem de cliente" />
              </Anchor>
            </Tooltip>
          )}

          {message.content?.length > 0 && (
            <Text size="sm">{message.content}</Text>
          )}
        </Box>

        <Tooltip label={formatDate(message.createdAt)} fz="xs">
          <Text fz={10} c="dimmed">
            {dayjs(message.createdAt).isSame(dayjs(), 'day')
              ? formatDateRelative(message.createdAt)
              : formatDate(message.createdAt)}
          </Text>
        </Tooltip>
      </Stack>
    </Group>
  )
}

const REASON_CANT_SEND_MESSAGE = {
  lead_not_owned: 'Oportunidade não está atribuída a você',
  customer_not_interacted_in_last_24_hours:
    'Você não pode enviar mensagens para o cliente se ele não interagiu com o nosso WhatsApp nas últimas 24 horas.',
}

export const Chat: React.FC<ChatProps> = ({
  initialMessages = [],
  customerId,
}) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages)
  const [newMessage, setNewMessage] = useState('')
  const viewport = useRef<HTMLDivElement>(null)

  const { data } = useQuery<
    GetCustomerChatQuery,
    GetCustomerChatQueryVariables
  >(GET_CUSTOMER_CHAT_QUERY, {
    variables: { customerId },
    pollInterval: 1000,
  })

  const [sendMessage, { loading: sendMessageLoading }] = useMutation(
    SEND_MESSAGE_MUTATION,
    {
      refetchQueries: ['GetCustomerChatQuery'],
    }
  )

  useEffect(() => {
    if (!data?.GetCustomerChat) return

    setMessages(data.GetCustomerChat.messages)
  }, [data])

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages])

  const scrollToBottom = () =>
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: 'instant',
    })

  const handleSendMessage = () => {
    if (!newMessage.trim()) return

    sendMessage({
      variables: {
        input: {
          message: newMessage,
          customerId: Number(customerId),
        },
      },
      onCompleted: () => {
        setNewMessage('')
      },
    })
  }

  return (
    <Stack h="95%">
      <ScrollArea
        type="scroll"
        scrollbars="y"
        viewportRef={viewport}
        style={{ flex: 1 }}
        styles={{
          viewport: {
            alignContent: messages.length > 0 ? 'flex-end' : 'center',
          },
        }}
      >
        <Stack py={24} p="xs" justify="center">
          {messages.map((message) => (
            <>
              {message.sender.type === 'MARKETING' && (
                <MarketingMessage message={message as Message} />
              )}

              {message.sender.type === 'CONSULTANT' && (
                <ConsultantMessage message={message as Message} />
              )}

              {message.sender.type === 'CUSTOMER' && (
                <CustomerMessage message={message as Message} />
              )}
            </>
          ))}
          {messages.length === 0 && (
            <Text
              display="block"
              c="dark.1"
              size="sm"
              ta="center"
              style={{ alignSelf: 'center' }}
            >
              Sem mensagens
            </Text>
          )}
        </Stack>
      </ScrollArea>

      <Stack>
        {!data?.GetCustomerChat?.canSendMessage && (
          <Text size="sm" c="red.8" ta="center">
            {
              REASON_CANT_SEND_MESSAGE[
                data?.GetCustomerChat
                  ?.reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
              ]
            }
          </Text>
        )}

        <Transition
          mounted={data?.GetCustomerChat?.suggestions.length > 0}
          transition="pop-bottom-right"
          duration={500}
        >
          {(styles) => (
            <Group justify="flex-end" gap="xs" wrap="nowrap" style={styles}>
              {data?.GetCustomerChat?.suggestions.map((suggestion) => (
                <Tooltip
                  label={suggestion.content}
                  key={suggestion.id}
                  multiline
                  w="300px"
                >
                  <Button
                    key={suggestion.id}
                    size="compact-xs"
                    fw="normal"
                    color="violet"
                    variant="light"
                    ta="center"
                    onClick={() => {
                      return setNewMessage(suggestion.content)
                    }}
                    style={{ cursor: 'pointer' }}
                    maw="150px"
                  >
                    <Text size="sm" truncate="end">
                      {suggestion.content}
                    </Text>
                  </Button>
                </Tooltip>
              ))}
            </Group>
          )}
        </Transition>

        <Group gap="sm">
          <Textarea
            autosize
            placeholder={
              data?.GetCustomerChat?.canSendMessage
                ? 'Digite uma mensagem...'
                : REASON_CANT_SEND_MESSAGE[
                    data?.GetCustomerChat
                      ?.reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
                  ]
            }
            value={newMessage}
            onChange={(e) => setNewMessage(e.currentTarget.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            style={{ flex: 1 }}
            disabled={!data?.GetCustomerChat?.canSendMessage}
          />

          <Button
            onClick={() => handleSendMessage()}
            loading={sendMessageLoading}
            disabled={
              sendMessageLoading || !data?.GetCustomerChat?.canSendMessage
            }
          >
            Enviar
          </Button>
        </Group>
      </Stack>
    </Stack>
  )
}

import { useEffect } from 'react'

import { navigate, PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import RedirectLayout from 'src/layouts/RedirectLayout/RedirectLayout'

import { useAuth } from './auth'
import AcquisitionLayout from './layouts/AcquisitionLayout/AcquisitionLayout'
import AuthLayout from './layouts/AuthLayout/AuthLayout'
import LearningActivityLayout from './layouts/LearningActivityLayout/LearningActivityLayout'
import LearningLayout from './layouts/LearningLayout/LearningLayout'
import LearningTeacherLayout from './layouts/LearningTeacherLayout/LearningTeacherLayout'
import LearningTeacherStudentGradeLayout from './layouts/LearningTeacherStudentGradeLayout/LearningTeacherStudentGradeLayout'
import LearningTestLayout from './layouts/LearningTestLayout/LearningTestLayout'
import LearningWritingActivityLayout from './layouts/LearningWritingActivityLayout/LearningWritingActivityLayout'

const TeacherRedirect = ({ children }) => {
  const { currentUser, isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated && currentUser?.roles?.includes('teacher')) {
      navigate('/learning/teacher/classes')
    }
  }, [isAuthenticated, currentUser])

  return <>{children}</>
}

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route notfound page={NotFoundPage} />

      <Set wrap={AuthLayout}>
        <Route path="/login" page={OnboardingLoginPage} name="login" />
        <Route path="/access/forgot" page={OnboardingForgotPasswordPage} name="forgot" />
        <Route path="/access/reset" page={OnboardingResetPasswordPage} name="reset" />
        <Route path="/signup" page={OnboardingSignupPage} name="signUp" />
      </Set>

      <PrivateSet unauthenticated="login">
        <Set wrap={RedirectLayout}>
          <Route path="/" page={OnboardingHomePage} name="home" />
        </Set>

        <PrivateSet unauthenticated="home" roles="sales_consultant">
          <Set wrap={AcquisitionLayout}>
            <Route path="/acquisition/leads" page={AcquisitionLeadsPage} name="leads" />
            <Route path="/acquisition/customers" page={AcquisitionCustomersPage} name="customers" />
            <Route path="/acquisition/reports" page={AcquisitionReportsPage} name="reports" />
          </Set>

          <Route path="/acquisition/leads/{id:Int}" page={AcquisitionLeadPage} name="lead" />
        </PrivateSet>

        <Set wrap={LearningLayout}>
          <Set wrap={TeacherRedirect}>
            <Route path="/learning/resources" page={LearningResourcesPage} name="learningResourcesPage" />
            <Route path="/learning/dashboard" page={LearningActivityExecutionUnitsPage} name="learningActivityExecutionUnitsPage" />
            <Route path="/learning/unit/lessons" page={LearningActivityExecutionLessonsPage} name="learningActivityExecutionLessonsPage" />
            <Route path="/learning/activities/writing" page={LearningWritingWritingActivitiesPage} name="learningWritingWritingActivitiesPage" />
            <Route path="/learning/tests" page={LearningTestExecutionTestsPage} name="learningTestExecutionTestsPage" />
          </Set>

          <PrivateSet unauthenticated="home" roles="admin">
            <Route path="/learning/admin" page={LearningAdminHomePage} name="learningAdminHomePage" />
            <Route path="/learning/admin/activities" page={LearningAdminActivitiesListPage} name="learningAdminActivitiesList" />
            <Route path="/learning/admin/activities/{id:Int}" page={LearningAdminEditActivityPage} name="learningAdminEditActivity" />
            <Route path="/learning/admin/levels" page={LearningAdminLevelsListPage} name="learningAdminLevelsList" />
            <Route path="/learning/admin/levels/{levelId:Int}/modules" page={LearningAdminModulesListPage} name="learningAdminModulesList" />
            <Route path="/learning/admin/modules/{moduleId:Int}" page={LearningAdminEditModulePage} name="learningAdminEditModule" />
            <Route path="/learning/admin/activities/create" page={LearningAdminCreateActivityPage} name="learningAdminCreateActivity" />
            <Route path="/learning/admin/resources" page={LearningAdminResourcesListPage} name="learningAdminResourcesList" />
            <Route path="/learning/admin/guide/unit/{unitId:Int}/lesson/{lessonId:Int}" page={LearningAdminLessonGuidePage} name="learningAdminLessonGuidePage" />
            <Route path="/learning/admin/guide/unit/{unitId:Int}" page={LearningAdminUnitGuidePage} name="learningAdminUnitGuidePage" />
            <Route path="/learning/admin/tests" page={LearningAdminStudentTestPage} name="learningAdminStudentTestPage" />
            <Route path="/learning/admin/level/guide/{levelId:Int}" page={LearningAdminLevelGuidePage} name="learningAdminLevelGuidePage" />
          </PrivateSet>
        </Set>

        <Set wrap={LearningActivityLayout}>
          <Set wrap={TeacherRedirect}>
            <Route path="/learning/unit/{unitId:Int}/lessons/{lessonId:Int}" page={LearningActivityExecutionActivityPage} name="learningActivityExecutionActivityPage" />
          </Set>
        </Set>

        <Set wrap={LearningWritingActivityLayout}>
          <Set wrap={TeacherRedirect}>
            <Route path="/learning/unit/{unitId:Int}/lessons/{lessonId:Int}/writing-activity/{activityId:Int}" page={LearningWritingWritingActivityPage} name="learningWritingWritingActivityPage" />
          </Set>
        </Set>

        <Set wrap={LearningTestLayout}>
          <Set wrap={TeacherRedirect}>
            <Route path="/learning/tests/{testId:Int}/instructions" page={LearningTestExecutionTestInstructionsPage} name="learningTestExecutionTestInstructionsPage" />
            <Route path="/learning/student-tests/{studentTestId:Int}" page={LearningTestExecutionTestActivityPage} name="learningTestExecutionTestActivityPage" />
            <Route path="/learning/tests/finished" page={LearningTestExecutionTestFinishedPage} name="learningTestExecutionTestFinishedPage" />
          </Set>
        </Set>

        <PrivateSet unauthenticated="home" roles="teacher">
          <Set wrap={LearningTeacherLayout}>
            <Route path="/learning/teacher/classes" page={LearningTeacherClassesPage} name="learningTeacherClassesPage" />
            <Route path="/learning/teacher/classes/{classId:Int}" page={LearningTeacherClassPage} name="learningTeacherClassPage" />

            <Route path="/learning/teacher/classes/{classId:Int}/writing/students" page={LearningWritingClassStudentsPage} name="learningWritingClassStudentsPage" />
            <Route path="/learning/teacher/classes/{classId:Int}/writing/students/{studentId:Int}" page={LearningWritingClassStudentPage} name="learningTeacherClassWritingStudentPage" />
            <Route path="/learning/teacher/classes/{classId:Int}/tests/students" page={LearningTestGradingTestClassStudentsPage} name="learningTeacherClassTests" />

            <Route path="/learning/teacher/guides/levels" page={LearningTeacherLevelsGuidesPage} name="learningTeacherLevelsGuidesPage" />
            <Route path="/learning/teacher/guides/levels/{levelId:Int}" page={LearningTeacherLevelGuidePage} name="learningTeacherLevelGuidePage" />
            <Route path="/learning/teacher/guides/levels/{levelId:Int}/units" page={LearningTeacherUnitsGuidesPage} name="learningTeacherUnitsGuidesPage" />
            <Route path="/learning/teacher/guides/levels/{levelId:Int}/units/{unitId:Int}" page={LearningTeacherUnitsGuidesPage} name="learningTeacherUnitsGuidesUnitPage" />
            <Route path="/learning/teacher/guides/levels/{levelId:Int}/units/{unitId:Int}/lessons/{lessonId:Int}" page={LearningTeacherLessonGuidePage} name="learningTeacherLessonGuidePage" />
          </Set>

          <Set wrap={LearningTeacherStudentGradeLayout}>
            <Route path="/learning/teacher/student-test/{studentId:Int}/class/{classId:Int}/grade/{studentTestId:Int}" page={LearningTestGradingStudentGradePage} name="learningTestGradingStudentGradePage" />
            <Route path="/learning/teacher/student-test/{studentId:Int}/class/{classId:Int}/grade/{studentTestId:Int}/activity/{activityId:Int}" page={LearningTestGradingStudentGradePage} name="learningTestGradingStudentGradePageActivity" />
            <Route path="/learning/teacher/finish-grade/{studentTestId:Int}/class/{classId:Int}/student/{studentId:Int}" page={LearningTestGradingFinishStudentGradePage} name="learningTestGradingFinishStudentGradePage" />
            <Route path="/learning/teacher/student/{studentId:Int}/class/{classId:Int}/summary/grade/{grade:Float}/grade-at/{gradedAt}" page={LearningTestGradingStudentGradeSummaryPage} name="learningTestGradingStudentGradeSummaryPage" />
          </Set>

          <Set wrap={LearningWritingActivityLayout}>
            <Route path="/learning/teacher/classes/{classId:Int}/writing/students/{studentId:Int}/unit/{unitId:Int}/lessons/{lessonId:Int}/activity/{activityId:Int}" page={LearningWritingClassStudentActivityPage} name="learningTeacherClassStudentActivityPage" />
          </Set>
        </PrivateSet>
      </PrivateSet>
    </Router>
  )
}

export default Routes

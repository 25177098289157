import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

dayjs.extend(relativeTime)
dayjs.locale('pt-BR')

export const formatDate = (date: string) =>
  dayjs(date).format('DD/MM/YYYY HH:mm')
export const formatDateRelative = (date: string) => dayjs(date).fromNow()

export { dayjs }

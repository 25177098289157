import {
  Anchor,
  Button,
  CSSVariablesResolver,
  MantineProviderProps,
  PasswordInput,
  TextInput,
  Textarea,
  rem,
} from '@mantine/core'

export const theme: MantineProviderProps['theme'] = {
  colors: {
    cnaBlue: [
      '#DEDFF2',
      '#D3D4EE',
      '#B5B7E3',
      '#979AD8',
      '#7276CA',
      '#5B60C2',
      '#4247B3',
      '#373B95',
      '#2C2F77',
      '#212359',
    ],
    cnaRed: [
      '#FDE2E7',
      '#FBC6D0',
      '#F8A0B0',
      '#F57A90',
      '#F35E78',
      '#F03858',
      '#ED1941',
      '#BD0F2E',
      '#930B24',
      '#71091C',
    ],
    gray: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343A40',
      '#212529',
    ],
    green: [
      '#EBFBEE',
      '#D3F9D8',
      '#B2F2BB',
      '#8CE99A',
      '#69DB7C',
      '#51CF66',
      '#40C057',
      '#37B24D',
      '#2F9E44',
      '#2B8A3E',
    ],
    dark: [
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5c5f66',
      '#373A40',
      '#2C2E33',
      '#25262b',
      '#1A1B1E',
      '#141517',
      '#101113',
    ],
  },
  primaryColor: 'cnaRed',
  primaryShade: 6,
  radius: {
    md: rem(12),
  },
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(20),
    xl: rem(24),
  },
  spacing: {
    xs: rem(10),
    sm: rem(12),
    md: rem(16),
    lg: rem(20),
    xl: rem(24),
  },
  headings: {
    fontWeight: '500',
    sizes: {
      h1: {
        fontSize: rem(40),
        lineHeight: '1.3',
      },
      h2: {
        fontSize: rem(32),
        lineHeight: '1.35',
      },
      h3: {
        fontSize: rem(24),
        lineHeight: '1.4',
      },
      h4: {
        fontSize: rem(20),
        lineHeight: '1.45',
      },
      h5: {
        fontSize: rem(16),
        lineHeight: '1.5',
      },
      h6: {
        fontSize: rem(14),
        lineHeight: '1.5',
      },
    },
  },
  fontFamily: 'Poppins',
  components: {
    Anchor: Anchor.extend({
      styles: () => ({
        root: {
          fontWeight: '500',
        },
      }),
    }),
    Button: Button.extend({
      styles: (theme, props) => {
        const common = {
          root: {
            borderRadius: props.radius || theme.radius.xl,
          },
        }
        if (props.size === 'lg') {
          return {
            ...common,
            root: {
              ...common.root,
              fontSize: theme.fontSizes.md,
              fontWeight: '400',
            },
          }
        }
        return {
          ...common,
        }
      },
    }),
    PasswordInput: PasswordInput.extend({
      styles: (theme, props) => {
        if (props.size === 'lg') {
          return {
            innerInput: {
              fontSize: theme.fontSizes.md,
            },
            label: {
              fontSize: theme.fontSizes.md,
              fontWeight: '400',
            },
          }
        }
      },
      defaultProps: {
        radius: 'md',
      },
    }),
    TextInput: TextInput.extend({
      styles: (theme, props) => {
        if (props.size === 'lg') {
          return {
            input: {
              fontSize: theme.fontSizes.md,
            },
            label: {
              fontSize: theme.fontSizes.md,
              fontWeight: '400',
            },
          }
        }
      },
      defaultProps: {
        radius: 'md',
      },
    }),
    Textarea: Textarea.extend({
      styles: (theme, props) => {
        if (props.size === 'lg') {
          return {
            input: {
              fontSize: theme.fontSizes.md,
            },
            label: {
              fontSize: theme.fontSizes.md,
              fontWeight: '400',
            },
          }
        }
      },
      defaultProps: {
        radius: 'md',
      },
    }),
  },
}

export const resolver: CSSVariablesResolver = () => ({
  variables: {},
  light: {
    '--mantine-color-body': '#FBFBFB',
  },
  dark: {},
})

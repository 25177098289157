import { useState } from 'react'

import { Text, Button, ButtonProps, Group } from '@mantine/core'

type ConfirmButtonProps = ButtonProps & {
  confirmQuestion?: string
  onClick: () => void
}

export function ConfirmButton({
  children,
  confirmQuestion,
  ...props
}: ConfirmButtonProps) {
  const [confirmed, confirm] = useState<boolean>(false)
  const [showConfirmationLabel, setShowConfirmationLabel] =
    useState<boolean>(false)

  const onClick = () => {
    if (confirmed) {
      props.onClick?.()
      confirm(false)
      setShowConfirmationLabel(false)
    } else {
      setShowConfirmationLabel(true)
      confirm(true)
    }
  }

  return (
    <Group wrap="nowrap" justify="flex-end">
      {showConfirmationLabel && (
        <Text c="dark.3" fz={10} ta="right">
          Clique novamente para confirmar
        </Text>
      )}

      <Button {...props} onClick={onClick}>
        {showConfirmationLabel ? confirmQuestion : children}
      </Button>
    </Group>
  )
}

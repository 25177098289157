import { useEffect } from 'react'

import {
  ActionIcon,
  Avatar,
  Badge,
  Code,
  Divider,
  Group,
  List,
  Loader,
  Modal,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconBrandWhatsapp,
  IconClock,
  IconEdit,
  IconInfoCircle,
  IconLock,
} from '@tabler/icons-react'
import { format } from 'date-fns'
import {
  GetLeadQuery,
  GetLeadQueryVariables,
  Lead,
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { GET_LEAD_BY_ID } from 'src/graphql/acquisition/lead-management'
import {
  LIST_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATION_AS_READ_MUTATION,
} from 'src/graphql/acquisition/lead-notifications'
import { mask } from 'src/helpers/mask'

import { Chat } from './Chat'
import { CustomerEventsTimeline } from './CustomerEventsTimeline'
import CustomerForm from './CustomerForm'
import { LeadActions } from './LeadActions'

type ViewLeadProps = {
  id: number
}

function ViewLead({ id }: ViewLeadProps) {
  const { data, loading } = useQuery<GetLeadQuery, GetLeadQueryVariables>(
    GET_LEAD_BY_ID,
    {
      variables: { id },
    }
  )
  const [markLeadAsRead] = useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MARK_NOTIFICATION_AS_READ_MUTATION, {
    refetchQueries: [LIST_NOTIFICATIONS_QUERY],
  })
  const [
    whatsappInstructionsOpen,
    { open: openWhatsappInstructions, close: closeWhatsappInstructions },
  ] = useDisclosure(false)

  useEffect(() => {
    markLeadAsRead({ variables: { input: { leadId: id } } })
  }, [data])

  const [
    openedEditingModal,
    { open: openEditingModal, close: closeEditingModal },
  ] = useDisclosure(false)

  if (loading) {
    return (
      <Group p="md" justify="center" align="center" h="100%">
        <Loader size="xl" />
      </Group>
    )
  }

  const lead = data.GetLead

  const _handleSuccess = () => {
    notifications.success({
      title: 'Transferência realizada',
      message: 'Atendimento transferido com sucesso!',
    })
  }

  return (
    <>
      <Modal
        opened={openedEditingModal}
        onClose={closeEditingModal}
        title="Editando dados do cliente"
        centered
        overlayProps={{ blur: 4 }}
      >
        <Stack gap="md">
          <CustomerForm
            data={lead.customer}
            onSuccess={() => {
              closeEditingModal()
            }}
          />
        </Stack>
      </Modal>
      <Group wrap="nowrap" align="stretch" mih="calc(100vh - 100px)">
        <Stack
          bg="gray.1"
          justify="stretch"
          flex={{
            base: 1,
            md: 0.5,
            lg: 1,
          }}
        >
          <Stack p="md">
            <Group align="center" mb="md" wrap="nowrap">
              <Avatar
                size={'lg'}
                name={lead.customer.name}
                color="initials"
                variant="outline"
              />

              <Stack gap="xs">
                <Title order={3}>{lead.customer.name}</Title>
              </Stack>
            </Group>

            <Group gap="md" mb="md" justify="space-between">
              <Stack align="flex-start" gap={4}>
                <Text size="xs" c="dark.2">
                  Atendido por
                </Text>
                {lead.owner && (
                  <Text size="xs" c="dark.3">
                    {lead.owner?.name}
                  </Text>
                )}
                {lead.owner === null && (
                  <Text c="red.9" fz="sm">
                    Aguardando atendimento
                  </Text>
                )}
              </Stack>

              <LeadActions lead={lead as Lead} />
              {lead.status === 'FINISHED' && (
                <Badge color="green">Finalizado</Badge>
              )}
            </Group>

            <Group gap="md" mb="md">
              <Stack align="flex-start" gap={4}>
                <Text size="xs" c="dark.2">
                  Criado em
                </Text>
                <Text size="xs" c="dark.3">
                  {format(new Date(lead.createdAt), 'dd/MM/yyyy hh:mm')}
                </Text>
              </Stack>

              <Stack align="flex-start" gap={4}>
                <Text size="xs" c="dark.2">
                  Última Atualização
                </Text>
                <Text size="xs" c="dark.3">
                  {format(new Date(lead.updatedAt), 'dd/MM/yyyy hh:mm')}
                </Text>
              </Stack>
            </Group>

            <Stack gap="md">
              <Group>
                <Title order={5}>Dados</Title>

                <Tooltip label="Editar dados">
                  <ActionIcon
                    size="sm"
                    radius="sm"
                    fz="xs"
                    color="dark"
                    variant="outline"
                    onClick={() => openEditingModal()}
                  >
                    <IconEdit size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Email
                </Text>
                <Text size="sm">{lead.customer.email}</Text>
              </Stack>
              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Telefone
                </Text>
                <Text size="sm">{mask(lead.customer.phone, 'phone')}</Text>
              </Stack>
              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Escola
                </Text>
                <Text size="sm">{lead.school}</Text>
              </Stack>
              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  CEP
                </Text>
                <Text size="sm">{mask(lead.customer.zipcode, 'cep')}</Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Canal
                </Text>
                <Text size="sm">{lead.channels?.join(', ')}</Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Produto
                </Text>
                <Text size="sm">{lead.product?.name || 'Não informado'}</Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Idioma
                </Text>
                <Text size="sm">{lead.product?.idiom || 'Não informado'}</Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Promoção
                </Text>
                <Text size="sm">
                  {lead.product?.promotionCode || 'Não informado'}
                </Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Nível de Conhecimento
                </Text>
                <Text size="sm">
                  {lead.product?.knowledgeLevel || 'Não informado'}
                </Text>
              </Stack>

              <Divider />

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  IP
                </Text>
                <Text size="sm">{lead.extra?.ip || 'Não informado'}</Text>
              </Stack>

              <Stack gap={4}>
                <Text c="dark.2" size="sm">
                  Origem
                </Text>
                <Text size="sm">{lead.extra?.origin || 'Não informado'}</Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          py="sm"
          gap={0}
          h="calc(100vh - 120px)"
          style={{
            position: 'sticky',
            top: 0,
            scrollPaddingTop: '100px',
          }}
          flex={{
            base: 1,
            md: 1.5,
            lg: 2,
          }}
        >
          <Group gap="xs">
            <ThemeIcon
              size="lg"
              radius="sm"
              color="dark.2"
              variant="transparent"
            >
              <IconBrandWhatsapp size={24} />
            </ThemeIcon>

            <Title order={4}>Conversa</Title>

            {process.env.NODE_ENV !== 'production' && (
              <>
                <Modal
                  opened={whatsappInstructionsOpen}
                  onClose={closeWhatsappInstructions}
                  title="Como testar Whatsapp"
                  size="lg"
                >
                  <Text>
                    Para testar o Whatsapp, você precisa dos seguintes passos:
                  </Text>

                  <List type="ordered" my="md">
                    <List.Item>
                      <Text>Baixe o Whatsapp no seu celular</Text>
                    </List.Item>
                    <List.Item>
                      <Text>
                        Envie a seguinte mensagem para o número{' '}
                        <a
                          href={`https://wa.me/${process.env.TWILIO_FROM_NUMBER}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {process.env.TWILIO_FROM_NUMBER}
                        </a>
                        :
                      </Text>

                      <Code c="white" color="dark.7">
                        join differ-six
                      </Code>
                    </List.Item>
                    <List.Item>
                      <Text>
                        Aguarde a resposta do Twilio. Após a confirmação, você
                        pode enviar mensagens por 24h.
                      </Text>
                    </List.Item>
                  </List>
                </Modal>
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="dark.3"
                  variant="transparent"
                  onClick={openWhatsappInstructions}
                >
                  <IconInfoCircle size={18} />
                </ActionIcon>
              </>
            )}
          </Group>

          <Divider mt="xs" p={0} />

          <Chat customerId={lead.customer.id} />
        </Stack>

        <Stack
          bg="gray.1"
          p="sm"
          mih="calc(100vh - 100px)"
          flex={{
            base: 1,
            md: 0.5,
            lg: 1,
          }}
        >
          <Tabs defaultValue="timeline">
            <Tabs.List>
              <Tabs.Tab value="timeline" leftSection={<IconClock size={16} />}>
                Eventos
              </Tabs.Tab>
              <Tabs.Tab
                value="privateNotes"
                leftSection={<IconLock size={16} />}
              >
                Anotações
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="timeline">
              <CustomerEventsTimeline customerId={lead.customer.id} />
            </Tabs.Panel>

            <Tabs.Panel value="privateNotes">
              <Stack p="md">
                <Title order={5}>Anotações</Title>

                <Text size="xs" c="dark.3">
                  Essas anotações sobre a oportunidade são privadas e não serão
                  compartilhadas com outros atendentes.
                </Text>

                <Divider />

                <Text size="xs" c="dark.3" ta="center">
                  Sem anotações
                </Text>
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </Group>
    </>
  )
}

export default ViewLead
